<template>
	<ion-page>
		<spot-header :title="feName" :canGoHome="false" :canGoBack="true" @pressedGoHome="$router.push('/home')" @pressedGoBack="$router.push('/login')"></spot-header>
		<spot-page-menu v-if="enabledItems.length!=1" :menuItems="enabledItems" :isReady="gettedModules" @selected="actionGoTo"></spot-page-menu>
		<spot-footer></spot-footer>
	</ion-page>
</template>

<style scoped></style>

<script>
import { openToast, settings, writeLog } from "@/plugins/common.js";
import { feName } from "@/plugins/variables.js";
import { IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import SpotFooter from "@/components/SpotFooter.vue";
import SpotHeader from "@/components/SpotHeader.vue";
import SpotPageMenu from "@/components/SpotPageMenu.vue";

export default defineComponent({
	name: "HomePage",
	components: {
		IonPage,
		SpotHeader,
		SpotPageMenu,
		SpotFooter,
	},
	data: () => ({
		menuItem: {
			picking: 0,
			shipping: 1,
			receipt: 2,
			listInventory: 3,
			},
		menuItems: [
			//{ id: 0, name: 'Picking', text: 'LISTE DI PRELIEVO', link: "/order/selection" + "?incoming=false&outgoing=false&picking=true", icon: "listCircle", enabled: null },
			{ id: 0, name: 'Picking', text: 'LISTE DI PRELIEVO', link: "/picking/selection", icon: "swapHorizontal", enabled: null },
			{ id: 1, name: 'Shipping', text: 'USCITA MERCE', link: "/order/selection" + "?incoming=false&outgoing=true&picking=false", icon: "logOut", enabled: null },
			{ id: 2, name: 'Receipt', text: 'INGRESSO MERCE', link: "/order/selection" + "?incoming=true&outgoing=false&picking=false", icon: "logIn", enabled: null },
			{ id: 3, name: 'ListInventory', text: 'INVENTARIO SENZA LISTA', link: "/inventory/selection" + "?free=true", icon: "clipboard", enabled: null }, //"?free=false"
		],
		enabledItems: [],
		gettedModules: false,
		feName,
		settings,
		openToast,
		writeLog,
	}),
	computed: {
		pickingItem() { return this.menuItems[this.menuItem.picking] },
		shippingItem() { return this.menuItems[this.menuItem.shipping] },
		receiptItem() { return this.menuItems[this.menuItem.receipt] },
		listInventoryItem() { return this.menuItems[this.menuItem.listInventory] },
	},
	async ionViewWillEnter() {
		await this.getAvailableModules();
		if (this.enabledItems.length==1) this.$router.push(this.enabledItems[0].link);
	},
	ionViewWillLeave() {
		this.gettedModules = false;
		// Do Nothing
	},
	methods: {
		// Settings & StartUp
		async getAvailableModules() {
			this.gettedModules = false;
			this.enabledItems = [];
			await this.getSettingParams();
			await this.fillAvailableItems();
			this.gettedModules = true;
		},
		async getSettingParams() {
			await this.getData("info/settings")
			.then((data) => {
				if (data && data.track) {
					this.settings.enablePickingList = (!data.track.enable_picking_list) ? false : (data.track.enable_picking_list == 0 ? false : true);
					this.settings.enableShipping = (!data.track.enable_shipping) ? false : (data.track.enable_shipping == 0 ? false : true);
					this.settings.enableReceipt = (!data.track.enable_receipt) ? false : (data.track.enable_receipt == 0 ? false : true);
					this.settings.enableInventory = (!data.track.enable_inventory) ? false : (data.track.enable_inventory == 0 ? false : true);
				}
			}).catch((error) => {
				this.openToast(error, "warning");
			});
		},
		async fillAvailableItems() {
			if (this.settings.enablePickingList == true) this.enabledItems.push(this.pickingItem);
			if (this.settings.enableShipping == true) this.enabledItems.push(this.shippingItem);
			if (this.settings.enableReceipt == true) this.enabledItems.push(this.receiptItem);
			if (this.settings.enableInventory == true) this.enabledItems.push(this.listInventoryItem);
			// this.gettedModules = true;
		},
		// Database
		async getData(tableName) {
			let data = await this.$store.dispatch("tables/getInfoSettings", tableName )
			return data;
		},
		// Actions
		actionGoTo(target) {
			this.writeLog(target);
			switch (target.id) {
				case this.pickingItem.id:
					//this.$router.push("/order/selection" + "?incoming=false&outgoing=false&picking=true");
					this.$router.push("/picking/selection");
					break;
					case this.shippingItem.id:
					this.$router.push("/order/selection" + "?incoming=false&outgoing=true&picking=false");
					break;
				case this.receiptItem.id:
					this.$router.push("/order/selection" + "?incoming=true&outgoing=false&picking=false");
					break;
				case this.listInventoryItem.id:
					this.$router.push("/inventory/selection" + "?free=true"); //"?free=false"
					break;
			}
		}
	}
});
</script>
